//MIXINS
@mixin fontStyle($size, $height, $weight: 400) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
}

@mixin dflex($justify, $align, $wrap: nowrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}
