/* Global styles */
:root {
    font-size: 52%;
    line-height: 1.2;
    color: #555;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  img {
    max-width: 100%;
  }
  ¨ a {
    text-decoration: none;
    color: inherit;
  }
  
  p {
    font-size: 2.2rem;
    line-height: 1.4;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
  }
  
  
  //GRID
  $gutter: 3rem;
  
  .grid {
    @include dflex(flex-start, center, wrap);
    gap: $gutter;
  }
  .grid-1-4 {
    width: calc((100% - (#{$gutter} * 3)) / 4);
  }
  .grid-2-4 {
    width: calc((100% - #{$gutter}) / 2);
  }
  .grid-1-3 {
    width: calc((100% - (#{$gutter} * 2)) / 3);
  }
  .grid-2-3 {
    width: calc(((100% - (#{$gutter})) / 3) * 2);
  }
  
  .light {
    color: $light;
  }
  
  .center {
    text-align: center;
  }